.job {

    &-wrapper {
        padding-top: 8em;
        padding-bottom: 8em;
        //background: black;
    }

    &-description {
        padding-top: 2em;
        padding-bottom: 4em;
    }
    &-slider__wrapper {
        display: flex;
    }
    
    &-slider {
        //width: 100%;
        position: relative;
        //background: red;
        width: calc(1100px - 6em);
        overflow: hidden;
        //overflow-x: hidden;
        //background: red;
        //cursor: grab;
     }

    &-slider_content {
        position: relative;
        display: flex;
        
        //width: 100%;
       //background: green;
       //justify-content: space-between;
    }
    &-slider__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 3em;
        min-height: 100%;
        //background: grey;
        cursor: pointer;
    }
    &-slider__left .scroll-UI {
        transform: rotate(270deg);
        //background: red;
    }
    &-slider__right .scroll-UI {
        transform: rotate(90deg);
        //background: red;
    }

    &-card {
        //display: flex;
        //min-width: 324px;
        min-width: calc(33.33% - 10.6px);
        //outline: solid 1px var(--accent-color);
        border-radius: 10px;
        background: white;
        
        margin-right: 16px;

    
        h3 {
            margin-left: 0;
        }
        
        img {
            //border-radius: 10px 10px 0px 0px;
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            filter: grayscale(0.6);
        }

        * > {
            :not(img) {
                margin-bottom: 1rem;
            }
        }

    }
    &-card_content {
        margin: 2rem;
        h3 {
            line-height: 1.5rem;
        }
    }

    &-cta {
        margin: 2em 0;
        gap: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.job-arrow {
    border: solid white;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 6px;
  }
  
  .job-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .job-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
@media (max-width: 480px) {
    .job-card {
        min-width: 100%; // Ensure it takes the full width on mobile
        margin-right: 8px; // Small spacing between cards
    }
    .job-slider__wrapper {
        padding: 0 1em; // Adjust padding for better centering
    }
    .job-cta {
        flex-direction: column;
    }
}
@media (min-width: 481px) and (max-width: 768px) {

    .job-card {
        min-width: calc(50% - 8px);
    }
}